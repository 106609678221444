/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
  transition: transform 300ms ease-in-out;
  transition-delay: 100ms;
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open {
  transform: scale(1);
  /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.flag-select__option__icon,
.flag-select__option__label {
  display: inline-block;
  vertical-align: inherit;
}

button > .flag-select__option > .flag-select__option__icon {
  top: 0;
}
